import React from "react"
import EventLayout from "../../components/layouts/eventLayout"

const BusinessAfterHoursIndexPage = props => (
  <EventLayout pageTitle="Business After Hours">
    <p>
      Business After Hours are exciting business social events that provide an excellent opportunity for Chamber 
      members and prospective members to network, establish and renew relationships. This is also an opportunity 
      for the host to showcase their business and/or celebrate a special occasion or anniversary.  
    </p><br />
    <p>
      Business After Hours are generally held on a quarterly basis, 5:00 pm until 6:30 pm at host’s business or 
      selected venue.  Chamber staff will work with host to select a mutually agreed upon date.  Every effort will 
      be made to avoid scheduling conflicts with other community events. 
    </p><br />
    <p>
      Chamber staff will brief each Business After Hours host three months prior to the event.  Hosts will sign a 
      “Hosting Agreement” that they have been briefed, have read all materials provided, and understand their responsibilities.
    </p><br />
    <p>
      Chamber staff will develop a special invitation with the host’s logo four weeks prior to the event.  This invitation 
      will be provided to the host for the host’s guest list.  Chamber staff will send event notification via email/US 
      Postal Service to the Chamber membership, and elected and appointed city/county officials.  
    </p><br /><br />
    <h3 css={{marginBottom: `1rem`}}>Guidelines for Hosts:</h3>
    <ul css={{marginLeft: `2rem`, '& li': {marginBottom: `1rem`}}}>
      <li>
        Business After Hours host agrees to pay a $25 BAH host fee, which the Chamber matches for the “Jackpot Drawing”.
      </li>
      <li>
          Business After Hours host agrees to provide handicap accessible location with adequate parking. If the location is not at 
          the host business, the selected venue must be approved by Chamber Board.   
      </li>
      <li>
        Business After Hours host will provide the Chamber their company logo for promotional purposes at least 60 days in advance.
      </li>
      <li>
        Business After Hours host will provide hors d’oeuvres and beverages for expected guests.
      </li>
      <li>
        Business After Hours host is encouraged to invite their own customers and vendors and to make personal phone calls reminding 
        people of Business After Hours the day before and morning of the event. Newspaper/radio advertisements are the responsibility of the host.  
      </li>
      <li>
        Business After Hours host is encouraged to provide door prizes.  
      </li>
    </ul>

    <div css={{margin: `2rem 0`}}>
      <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
      }} href="/docs/Business-After-Hours-Host-Agreement.pdf">Business After Hours Host Agreement</a>
    </div>

  </EventLayout>
)

export default BusinessAfterHoursIndexPage
